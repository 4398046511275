/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif', serif;
  font-size: 15px;
  background-color: black;
  color: white;
}
.ailogo {
  width:100px;
}
.byline {
  float:left;
}
hr {
color: white;
  padding:0;
  margin:0;
}
ol {
  text-align: left;
}
ol li {
  padding-bottom:10px;
  margin-right:40px;
}
.symbol {
  float:left;
  width:100px;
  margin-right:20px;
  margin-left:10px;
}
.breadcrumbs {
  text-align: left;
  padding: 0;
}
.breadcrumbs li {
  list-style: none;
  display:inline-flex;
  margin-top:30px;
  padding:0;

}
.breadcrumbs ul
{  padding-inline-start: 20px;
}
.author {
  font-size: 14px;
  text-align: left;
  display:block;
  margin-left:20px;

}
.navbar {

  position: fixed; /* Fixed positioning */
  top: 0;         /* Anchor it to the top */
  left: 0;        /* Anchor it to the left */
  width: 100%;    /* Make it full width */
  z-index: 1000;  /* Ensure it's on top of other content */
}

caption {
  display:block;
  font-size:1em;
}
p  {
  margin-left:20px;
  margin-right:20px;
}

.home-text {
  margin: 0 auto;
  margin-bottom:50px;
  font-size: 1.5em;
  margin-left:25px;
  margin-right:25px;
}
.content {
}
.content p {
  display:block;
  text-align: center;
  font-size:1.25em;

}
p.subscribe {
text-align: center;
}

.content h1, h2 {
  text-align:center;
  color:black;

}
.content h1 {
  font-size: 2em;
}
.contentauth h1 {
  font-size: 2em;
  text-align:left;
}
.contentauth {
}
.content ul, div {
  background-color: white;
}

.App {
  text-align: center;
}
.socialicon {
  display:inline-flex;
}
.hero img {
  max-width:500px;
  margin-top:50px;
  float:right;
}
.hero-img {
  margin-top:210px;
  min-height: 400px;
}
.herosmall {
  width: 200px;
  float:left;
  margin-right:20px;

}
.extra {
  padding-top:20px;
}
.sociallinks {
  color:white;
  text-align: center;
}
.sociallinks a {
  z-index: 99;
  justify-content: center;
  text-align: center;
  padding-left:5px;
}
.parallax {
  background: url("./img/Lord_Toph-Pieter_Henket-cropped.png");
  min-height: 100px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.blog-summary {
  height: 200px;
  padding:0px 0 0 20px;
  background-color: white;
  border-top: white;
  font-size:.8em;

}
.blog-summary h2 {
  padding-top: 2px;
}
.filebaby {
  background-color: none;
}
.logo {
  width:400px;
  padding:40px 10px 13px 0px;
}
.pagelogo {
  /* float:right */
  width:225px;
  margin-top:50px;
}

 .nav-item {
  font-size: 14px;
  list-style: none;
  padding:0;
}
.footer-links {
  display:block;
}
.insetImageLeft {
  display: block; /* Display block to make sure it's not inline */
  width: 250px; /* Set the width of the image */
  float: left; /* Float to the left */
  margin-right: 20px; /* Add some space between the image and the text */
}

/* Float the image to the right */
.insetImageRight {
  display: block; /* Display block to make sure it's not inline */
  width: 250px; /* Set the width of the image */
  float: right; /* Float to the right */
  margin-left: 20px; /* Add some space between the image and the text */
}
.imageContainer {
  text-align: center; /* Centers the content (the image) inside the container */
  padding:0;
}
/* Style for the dropdown button */
.content {
  margin-top:190px;
}

.dropbtn {
  color: black;
  padding: 0 0 0 0;
  font-size: 14px;
  border: none;
  cursor: pointer;
  text-decoration: none; /* Remove underline from link */
  display: inline-block; /* Allow pseudo-elements */
  position: relative; /* Establish positioning context */
  background-color: white;

}

/* Add a small arrow after the dropdown link */
.dropbtn::after {
  display:none;
  content: ' ▼';
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: #f7d347;
  padding-right: 5px;
  position: absolute; /* Position the arrow relative to the dropbtn */
  right: -20px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

.contact p {
  text-align: center;
  color: black;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #bdaba1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f9f9f9;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
}
span.readytoinspect {
display:none;
}
/* Style for the image */
.centeredImage {
  display: block; /* Treats the image as a block-level element */
  margin: 0 auto; /* Sets the top and bottom margins to 0 and left and right margins to auto, centering the image */
  width: 100%; /* Optional: sets the width of the image */
}
.centeredImageFull {
  display: block; /* Treats the image as a block-level element */
  margin: 0 auto; /* Sets the top and bottom margins to 0 and left and right margins to auto, centering the image */
  width: 500px; /* Optional: sets the width of the image */
}
.leftImage {
  display:block;
  margin-right: 10px;
  margin-top:10px;
  margin-bottom:10px;
  float:left;
}
.rightImage {
  display:block;
  margin-left: 10px;
  margin-top:10px;
  margin-bottom:10px;
  float:right;
  width:200px;
}
.blogpost {
  margin-top: 250px;
}
.blog-summary h2 {
  text-align:left;
}
.hero {
  background-color: grey; /* Soft blue background for hero section */
}
.hero-img {
  width:100%;
}
.hero-img img {
  transition: opacity 2s ease-in-out;

}
.hero-img-blog {
  width:25%;
}
.top-heading {
  float:left;
}
/*this is used in inspect gif*/
.services {
  padding: 25px 0;
  text-align: center;
  background-color: white;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);

}
.serviceslogo  {
  width: 100px;
  margin-top:50px;
}
.services h2 {
  color: #2b2d30;
}

.footer {
  background-color: white;
  padding: 10px;
  min-height: 50px;
  background-repeat: no-repeat;
  color: white;
  background-position: center;
  list-style:none;
  text-align:left;
}
.footer ol {
  list-style: none;
  display:inline-flex;
}
.copyright {
  text-align:center;
  color:white;
}

.footer img {
  width:32px;
  float:right;
  padding-left: 5px;
}
.footer li {
  display:flex;
  background-color: black;
}
.privacy-policy {
  padding: 50px 20px;
  background-color: #61dafb;
  text-align: left;
  font-size: 0.5em;
}

.privacy-policy h2, .terms-of-use h2 {
  color: #2b2d30; /* Or the primary color of your theme */
}
.terms-of-use {
  padding: 0 20px;
  background-color: #61dafb;
  text-align: left;
  font-size: 0.5em;
}
/* Add to App.css */

.content a, a:visited {
  text-decoration:underline;
  color: black;
}

.video iframe {
  width: 100%;
  max-width: 560px; /* Maximum width of the video */
  height: auto; /* Adjust height automatically */
  aspect-ratio: 16 / 9; /* Maintain aspect ratio of 16:9 */
  margin-top: 60px;
}

/* Styles for when menu is active */
.active {
  transform: translateX(0);
}
.homepage-text {
  display: flex;
  margin-bottom: 5px;
}
.homepage-text a {
  display: contents;
  color:white;
  font-style: italic;
}
.art {
  font-size:1.5em;
  padding:100px;
  background-color: #d6d6d3;
  font-weight:bold;
  background-image: url("./img/Lord_Toph-A_Dance_In_Central_Park-cropped.png");
  background-repeat:no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  margin-bottom:0;
}
.blog-summary span {
  height: 300px;
  }
.art a {
  color: blue;
  text-decoration: underline;
}
.music {
  font-size:1.5em;
  padding:200px;
  background-repeat:no-repeat;
  background-size: cover;
  background-image: url("./img/Lord_Toph_music_1.png");
  color:black;
  font-weight:bold;
  margin-bottom:0;
}
.books {
  font-size:1.5em;
  padding:200px;
  background-color: #495a71;
  background-repeat:no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  background-image: url("./img/Lord_Toph-Noctvrnal_Passages-cropped.png");
  color:white;
  font-weight:bold;
  margin-bottom:0;
}
.blog-image  {
  height:400px;
}
.footer {
  background-color: black;
  color:white;
  margin:0;
  padding:0;
  text-align: center;
}
.footer ol {
  background-color: black;
}
.copyright {
  text-align: center;
  color: white;
  background-color:black;
  display:block;
  margin:0;
}

.footer img {
  width:32px;
  padding-right:5px;
}
.footer li {
  background-color: black;
  text-align:center;
  list-style:none;
  display:inline-flex;
  padding:0;
  margin:0;
}
.sitelinks {
  display:block;
  list-style: none;
  justify-content: center;
  background-color: black;
}
.sitelinks a {
  color: white;
}
.sociallinks ol {
  text-align:center;
}
.links {
  text-align: center;
  width:100%;
}
.links a {
  color: white;
  padding-bottom:10px;
}
.sociallinks a {
  color: black;
}
.sociallinks li {
  list-style:none;
  padding:0;
  margin:0;
  float:left;
  text-align: center;
}
@media (min-width: 769px) {
  /* Hide the hamburger menu icon */
  .menu-icon {
    display: none;
  }

  /* Style the navigation list for larger screens */
  .nav-list {
    display: flex;
    justify-content: center; /* Center the nav items horizontally */
    align-items: center; /* Align the nav items vertically */
    list-style: none; /* Remove list bullets */
    padding-left: 0; /* Remove padding */
    background-color: white; /* Your desired background color */
    width: 100%; /* Full width of the menu */
    margin-top:0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }

  /* Style each navigation item */
  .nav-item {
    padding: 18px 10px; /* Add padding to each nav item for spacing */
    text-align:left;
  }

  /* Style each navigation link */
  .nav-item a {
    text-decoration: none; /* Remove underline from links */
    color: black; /* Set text color for the links */
  }

  /* Additional styles for the 'X' close icon, if needed */
  .menu-icon span {
    font-size: 2rem; /* Set a large font size for the 'X' icon */
    cursor: pointer; /* Change mouse to pointer when hovering over the 'X' */
  }
  .services {
    text-align: center;
    color:black;
  }
}
@media (max-width: 768px) {
  .menu {
    display: none; /* Hide by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(0, 0, 0, 0.9); /* Black overlay */
    background-color: white;
    /*background-image: url('./img/AdobeStock_124036778.jpg'); /* Add your image path here */
    background-size: cover; /* Cover the entire menu area */
    background-position: center; /* Center the background image */

    z-index: 99;
    justify-content: center;
    align-items: center;
  }

  .menu.active {
    display: flex; /* Show the full-screen menu */
    flex-direction: column;
  }

  .nav-list {
    display: none; /* Hide by default */
    list-style-type: none;
    width: 100%; /* Full width */
    height: 100%; /* Full height, if you want the menu to cover the entire screen */
    padding-top: 1.5rem; /* Adjust if needed */
    box-sizing: border-box;
    flex-flow: column nowrap;
  }

  .nav-list.active {
    display: flex; /* Show on active */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }

  .nav-item {
    color: black; /* Light text for visibility */
    padding: 1rem; /* Spacing around items */
    text-align: left;
    width: 100%; /* Full width */
    font-size: 1em;
  }
  .nav-item a {
    text-decoration: none;
    color: black; /* Light text for visibility */
    display: inline-block; /* Make the link fill the list item for easier touch */
  }

  .menu-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    z-index: 100; /* Above the overlay */
    margin-right: 5px;
  }

  .menu-icon div, .menu-icon span {
    width: 2rem; /* Size of the icon */
    height: 0.15rem; /* Height of the icon lines */
    background-color: black; /* Color of the icon lines */
    border-radius: 5px;
    transition: all 0.3s linear;
  }
  .menu-icon div {
    margin-bottom: 0.5rem;
  }

  .menu-icon span {
    height: auto; /* Auto height for the 'X' character */
    font-size: 2rem; /* Size of the 'X' */
    line-height: 2rem; /* Align 'X' with the icon lines */
    text-align:center;
    color:white;
  }


  /* Add a small arrow after the dropdown link */
  .dropbtn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-family: inherit; /* Match the font of other links */
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:black;
  }

  /* Style for the icons */
  .fa-caret-up, .fa-caret-down {
    margin-left: 0.5rem;
  }
  /* Add a small arrow after the dropdown link */
  .dropbtn::after {
    display: none;
    content: ' ▼';
    font-size: 0.8rem;
    margin-left: 0.5rem;
    color: #f7d347;
    padding-right: 5px;
    position: relative; /* Position the arrow relative to the dropbtn */
    right: -250px; /* Adjust as needed */
    top: 50%;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    margin: 0 0 0 0;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    float:right;
    margin-left:2rem;
    margin-right:2rem;
    position: relative;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #f1f1f1;

  }
  .dropdown-content {
    display: none;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropbtn {
  display:block;
    font-size:1em;
    font-weight:normal;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
  }

  .ailogo {
    width:100px;
  }
  .home-text {
    font-size: 1em;
    margin-left:20px;
    margin-right:20px;

  }


  .items ul {
    display: none;
  }
  .homepage-text {
    display: inline-block;
  }
  .content p {
    display:block;
    text-align: left;
  }
 .subscribe {
    text-align: center;
  }
  .content {
    margin-top:50px;
  }
  img.caifb {
    margin-top:50px;
    width:100%;
  }
  .art, .music, .books {
    font-size:1em;
    padding:50px;
    margin-bottom:0;

  }
  .logo {
    max-width: 150px;
  }
.caifb {
    padding:0px;
    margin:0px;
   display:block;
  }

  .odd {
    font-size:1em;
    padding:50px;
  }
  .leftImage {
    display:block;
    margin-right: 10px;
    margin-top:10px;
    margin-bottom:10px;
    width:100%;
    }
  @media (max-width: 399px) {
    .video iframe {
      width: 300px;
      height: auto; /* Adjust height automatically */
      aspect-ratio: 16 / 9; /* Maintain aspect ratio of 16:9 */
      margin-top: 60px;
    }

    .centeredImageFull {
      max-width:180px;
    }
    .footer img {
      width:180px;
    }
    h1 {
      font-size: 1.1em;
      text-align:left;
    }
    .blog-summary {
    height:auto;
    }
    .blog-summary h2 a {
      padding-top: 2px;
      align-content: left;
    }
  }
}
